export enum BREAKPOINT {
  XS = 490,
  SM = 768,
  MD = 1040,
  LG = 1800,
  XL = 1920,
}
export enum Subscription {
  Monthly = 'monthly',
  Annualy = 'yearly',
}

export enum PricingPlan {
  Free = 'free',
  Basic = 'basic',
  Pro = 'pro',
  Premium = 'premium',
}

export enum PricingTag {
  BestSeller = 'Best seller',
  Recommended = 'Recommended',
  BestValue = 'Best value',
}

export enum ICon {
  Whatsapp = 'whatsapp',
  Messenger = 'messenger',
  Phone = 'phone',
  Email = 'email',
  Instagram = 'instagram',
  Sms = 'sms',
  ContactForm = 'form',
  PoptinPopups = 'popups',
  Line = 'line',
  Telegram = 'telegram',
  GoogleMap = 'map',
  Viber = 'viber',
  Twitter = 'twitter',
  WeChat = 'wechat',
  SnapChat = 'snapchat',
  TikTok = 'tiktok',
  Skype = 'skype',
  Waze = 'waze',
  LinkedIn = 'linkedin',
  Vkontakte = 'vkontakte',
  Slack = 'slack',
  Discrod = 'discord',
  Teams = 'microsoft',
  CustomLink = 'custom',
  Contact = 'Contact Us',
  ScrollToTop = 'scrollToTop',
  HomePage = 'homePage',
  CartPage = 'cartPage',
  Zalo = 'zalo',
}
export enum WidgetIcon {
  WidgetIconChatBase = '1',
  WidgetIconChatSmile = '2',
  WidgetIconChatBubble = '3',
  WidgetIconChatDb = '4',
  CustomWidgetIcon = '5',
}
export enum Size {
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL',
  XXL = 'XXL',
  Custom = 'custom',
}

export enum DefaultState {
  Click = 'click',
  Default = 'always',
}

export enum Display {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

export enum LinkedinType {
  Personal = 'personal',
  Company = 'company',
}

export enum GroupCtaBehavior {
  HideClick = 1,
  ShowAll = 2,
}
export enum Device {
  Pc = 'pc',
  Mobile = 'mobile',
  MobileView = 'mobile-view',
  PcView = 'pc-view',
}
export enum TargetRuleOptions {
  PagesContain = 'contain',
  SpecificPage = 'exact',
  PagesStarting = 'start',
  PagesEndting = 'end',
}

export const sizeIcon = [
  {
    title: Size.S,
    value: '0.5',
  },
  {
    title: Size.M,
    value: '0.75',
  },
  {
    title: Size.L,
    value: '1',
  },
  {
    title: Size.XL,
    value: '1.25',
  },
  {
    title: Size.XXL,
    value: '1.5',
  },
  {
    title: Size.Custom,
    value: '40',
  },
];

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum Border {
  Square = 'squares',
  Conner = 'rounded',
  Circle = 'circle',
  TopRight = 'topRight',
  TopLeft = 'topLeft',
  BottomRight = 'bottomRight',
  BottomLefft = 'bottomLeft',
}

export enum DayOfTheWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export enum WorkingDayType {
  AllDay = 'all',
  CustomDay = 'custom',
}

export enum TargetPage {
  AllPage = 0,
  SpecificPages = 1,
  ExcludePages = 2,
}

export enum IconIds {
  Custom = 0,
  Default = 1,
  Icon2 = 2,
  Icon3 = 3,
  Icon4 = 4,
  Icon5 = 5,
  Icon6 = 6,
  Icon7 = 7,
  Icon8 = 8,
  Icon9 = 9,
  Icon10 = 10,
}

export enum AnimationScrollToTop {
  Auto = 'auto',
  Smooth = 'smooth',
}

export enum ChannelNames {
  whatsapp = 'WhatsApp',
  messenger = 'Messenger',
  phone = 'Phone',
  email = 'Email',
  instagram = 'Instagram',
  sms = 'SMS',
  form = 'Contact form',
  popups = 'Poptinpopups',
  line = 'Line',
  telegram = 'Telegram',
  map = 'Google Maps',
  viber = 'Viber',
  twitter = 'X',
  wechat = 'WeChat',
  snapchat = 'Snapchat',
  tiktok = 'TikTok',
  skype = 'Skype',
  waze = 'Waze',
  linkedin = 'LinkedIn',
  vkontakte = 'VKontakte',
  slack = 'Slack',
  discord = 'Discord',
  microsoft = 'Microsoft Teams',
  scrollToTop = 'Scroll to top',
  homePage = 'Home page',
  cartPage = 'Cart page',
  zalo = 'Zalo',
  custom = 'Custom',
  'Contact Us' = 'Contact Us',
}

export enum ContactFormInputType {
  Text = 'text',
  Date = 'date',
  Time = 'time',
  Email = 'email',
  Number = 'number',
}

export enum AvatarIds {
  Custom = 0,
  Default = 1,
  AvatarTwo = 2,
  AvatarThree = 3,
  AvatarFour = 4,
  AvatarFive = 5,
  AvatarSix = 6,
}

export enum Ga4Event {
  BlockifyImpression = 'impress_homebanner1',
  EstimatedImpression = 'impress_homebanner2',
  ReturnImpression = 'impress_homebanner3',
  SynctrackImpression = 'impress_st',
  OderTrackingImpression = 'impress_ot',
  BlockifyClick = 'click_homebanner1',
  EstimatedClick = 'click_homebanner2',
  ReturnClick = 'click_homebanner3',
  SynctrackClick = 'click_st',
  OderTrackingClick = 'click_ot',
}

export enum WidgetPlatform {
  Messaging,
  SocialMedia,
  Collaboration,
  ContactForm,
  Location,
  Navigation,
  Other,
}
