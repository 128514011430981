import { RootState } from '@/redux/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  isShowedBlockify: false,
  isSubmittedBlockify: false,
  isShowedRecommendedApps: false,
  isSubmittedEstimated: false,
  isSubmittedReturn: false,
  isShowedDynamicContentBanner: true,
  isShowedSyncTrack: false,
  isShowedOderTracking: false,
  isSubmittedSyncTrack: false,
  isSubmittedOderTracking: false,
};

export type SessionSliceKeys = keyof typeof initialState;

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    handleUpdateSession: (
      state,
      action: PayloadAction<{
        key: SessionSliceKeys;
        value: boolean;
      }>,
    ) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const isShowedBlockifySelector = createSelector(
  (state: RootState) => state.session,
  (state) => state.isShowedBlockify,
);

export const isSubmittedBlockifySelector = createSelector(
  (state: RootState) => state.session,
  (state) => state.isSubmittedBlockify,
);

export const isShowedRecommendedAppsSelector = createSelector(
  (state: RootState) => state.session,
  (state) => state.isShowedRecommendedApps,
);

export const isSubmittedEstimatedSelector = createSelector(
  (state: RootState) => state.session,
  (state) => state.isSubmittedEstimated,
);

export const isSubmittedReturnSelector = createSelector(
  (state: RootState) => state.session,
  (state) => state.isSubmittedReturn,
);

export const isShowedDynamicContentBannerSelector = createSelector(
  (state: RootState) => state.session,
  (state) => state.isShowedDynamicContentBanner,
);

export const isShowedSyncTrackSelector = createSelector(
  (state: RootState) => state.session,
  (state) => state.isShowedSyncTrack,
);

export const isShowedOderTrackingSelector = createSelector(
  (state: RootState) => state.session,
  (state) => state.isShowedOderTracking,
);

export const isSubmittedSyncTrackSelector = createSelector(
  (state: RootState) => state.session,
  (state) => state.isSubmittedSyncTrack,
);

export const isSubmittedOderTrackingSelector = createSelector(
  (state: RootState) => state.session,
  (state) => state.isSubmittedOderTracking,
);
